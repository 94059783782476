.expandable {
  position: relative;
  overflow: hidden;

  &:not(.expanded) {
    &::after {
      content: '';
      width: 100%;
      height: 70px;
      position: absolute;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, var(--color-grey-lighter) 100%);

      left: 0;
      bottom: 0;
    }

    &.expandable-white::after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, var(--color-white) 100%);
    }
  }

  &.expanded {
    max-height: 100% !important;

    & ~ .readmore.readmore-compact {
      position: relative;
      bottom: unset;
    }
  }

  & ~ .readmore {
    margin: var(--padding-xsmall) auto;
    display: flex;

    &.readmore-compact {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 100;
    }

    &.readmore-center {
      justify-content: center;
    }

    &.readmore-right {
      justify-content: right;
    }

    &.readmore-button {
      > a {
        border-radius: 4px;
        border: 2px solid var(--color-grey-dark);

        &:hover,
        &:focus,
        &:active {
          border-color: var(--color-black);
        }
      }
    }

    > a {
      padding: var(--padding-mini) calc(var(--padding-mini) + var(--padding-xsmall));
      cursor: pointer;
      color: var(--color-grey-dark);
      font-weight: 500;
      text-transform: uppercase;
      transition: all 200ms ease-in-out;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        color: var(--color-black);
      }
    }
  }
}
